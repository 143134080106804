.sidebar{
    width: 40%;
    min-width: 325px;
    z-index: 2;
}

.sidebar, .sidebar-top-text{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-top-background{
    position: relative;
    width: 100%;
    height: 100%;
}
.sidebar-top-background > img {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    right:0;
    top:0;
    z-index: -1;
    object-fit: cover;
}

.sidebar-top-text{
    margin-top: 100px;
}
.sidebar-top-text > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid black;
    border-radius: 100px;
}
.sidebar-top-text > h1 {
    color: white;
    font-size: 54px;
}

.sidebar-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    margin-bottom: 100px;
}
.sidebar-bottom > a {
    text-decoration: none;
    color: white;
}
.sidebar-bottom > a > i {
    font-size: 40px;
}

/* Media Queries */

/* 4k */
@media screen and (min-width: 3000px) and (min-height: 1795px){
    .sidebar-top-text > img {
        width: 300px;
        height: 300px;
        border-radius: 150px;
    }
    .sidebar-top-text > h1 {
        font-size: 72px;
    }
    .sidebar-bottom > a > i {
        font-size: 60px;
    }
}

/* UW */
@media screen and (min-width: 3000px) and (min-height: 1250px){
    .sidebar-bottom > a > i {
        font-size: 50px;
    }
}

/* Larger Screens */
@media  screen and (min-width: 1200px) and (max-height: 1080px){
    .sidebar-top-text > img {
        width: 125px;
        height: 125px;
        border-radius: 70px;
    }
    .sidebar-top-text > h1 {
        font-size: 36px;
    }
    .sidebar-bottom > a > i {
        font-size: 25px;
    }
}

/* Small Screens */
@media  screen and (max-width: 1200px) {
    .sidebar-top-text > img {
        width: 125px;
        height: 125px;
        border-radius: 70px;
    }
    .sidebar-top-text > h1 {
        font-size: 32px;
    }
    .sidebar-bottom > a > i {
        font-size: 25px;
    }
}

/* Mini Screens */
@media  screen and (max-width: 768px){
    .sidebar{
        width: 30%;
        min-width: 175px;
    }
    .sidebar-top-background > img {
        /* height: 109%; */
    }
    .sidebar-top-text > img {
        width: 75px;
        height: 75px;
        border-radius: 35px;
    }
    .sidebar-top-text > h1 {
        font-size: 24px;
    }
    .sidebar-bottom > a > i {
        font-size: 20px;
    }
}

/* Mobile */
@media  screen and (max-width: 480px){
    .sidebar{
        /* width: 20%;
        min-width: 125px; */
        width: 100%;
        padding: none;
    }
    .sidebar-top-text{
        margin-top: 20px;
    }
    .sidebar-bottom{
        margin-bottom: 20px;
    }
    .sidebar-top-background > img {
        min-height: auto;
        object-fit: cover;
        /* display: none; */
    }
}
