.site-wrapper{
  margin: 0;
  display: flex;
  min-height: 100vh;
  column-gap: 100px;
  background-color: rgb(47, 45, 45);
  font-family: Helvetica, Arial, sans-serif;
}

.page-section{
  width: 90%;
  max-width: 2500px;
  color: white;
}

.page-section > h2 {
  font-size: 30px;
}

.page-section > p {
  font-size: 20px;
}

.italic{
  font-style: italic;
}

textarea:focus, input:focus{
  outline: none;
}

/*Skills*/
#skill-key{
  color: #47bbff;
}
#skill-frameworks{
  color: #ffb13b;
}
#skill-languages{
  color: #2dbe65;
}

/* Media Queries */

/* 4k */
@media  screen and (min-width: 3000px) and (min-height: 1795px){
  .page-section > h2 {
    font-size: 40px;
  }
  .page-section > p {
    font-size: 30px;
  }
}

/* Larger Screens */
@media  screen and (min-width: 1200px) and (max-height: 1080px){
  .site-wrapper{
    column-gap: 50px;
  }
  .page-section > h2 {
    font-size: 20px;
  }
  .page-section > p {
    font-size: 16px;
  }
}

/* Small Screens */
@media  screen and (max-width: 1200px){
  .site-wrapper{
    column-gap: 50px;
    text-align: center;
  }
  .page-section > h2 {
    font-size: 16px;
  }
  .page-section > p {
    font-size: 14px;
  }
}

/* Mini Screens */
@media  screen and (max-width: 768px){
  .site-wrapper{
    column-gap: 50px;
  }
  .page-section > h2 {
    font-size: 16px;
  }
  .page-section > p {
    font-size: 12px;
  }
}

/* Mobile */
@media  screen and (max-width: 480px){
  .site-wrapper{
    column-gap: 25px;
    flex-direction: column;
    align-items: center;
  }
  .page-section{
    width: 100%;
  }
  .main-section{
    padding: 15px 15px 0px 15px;
  }
}
