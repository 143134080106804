.ContactMe,
.ContactMe-form,
.contact-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ContactMe {
    margin-top: -15px;
    row-gap: 10px;
    margin-bottom: 25px;
}

.ContactMe.messageSending .messageLoader{
    margin-top: 20px;
}

.ContactMe p {
    font-size: 20px;
}

.ContactMe i {
    font-size: 40px;
    width: 50px;
    text-align: center;
}

.ContactMe-form-wrapper,
.ContactMe-form-top,
.contact-option {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.ContactMe-form-wrapper {
    height: 235px;
    column-gap: 20px;
}

.ContactMe-form {
    height: 100%;
    justify-content: space-around;
}

.ContactMe-form>textarea {
    margin-top: 10px;
}

.contact-options {
    height: 100%;
    justify-self: flex-start;
    margin-top: -10px;
}

.contact-option {
    height: 60px;
}

.contact-option > a {
    text-decoration: none;
    color: white;
    font-size: 20px;
}


/*Form Inputs and TextArea*/

.ContactMe-form-top > input {
    padding: 12px;
    font-size: 16px;
    width: 250px;
    border: 5px solid rgb(21, 19, 19);
    border-radius: 10px;
}

.ContactMe-form > textarea {
    padding: 12px;
    font-size: 16px;
    height: 200px;
    width: 544px;
    resize: none;
    border: 5px solid rgb(21, 19, 19);
    border-radius: 10px;
}

.ContactMe-form-top>input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
    /* Firefox */
}

.ContactMe-form-top>input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Helvetica, Arial, sans-serif;
}

.ContactMe-form-top>input::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: Helvetica, Arial, sans-serif;
}

.ContactMe-form>textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: Helvetica, Arial, sans-serif;
    opacity: 1;
    /* Firefox */
}

.ContactMe-form-top>textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: Helvetica, Arial, sans-serif;
}

.ContactMe-form-top>textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: Helvetica, Arial, sans-serif;
}


/*Form Button*/

#sendEmail-Button {
    color: black;
    background-color: white;
    border: 5px solid rgb(21, 19, 19);
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition-duration: 300ms;
}

#sendEmail-Button:hover {
    background-color: #2dbe64;
    color: white;
}

/* Media Queries */

/* 4k */
@media  screen and (min-width: 3000px) and (min-height: 1795px){
    .ContactMe p, .contact-option > a {
        font-size: 30px;
    }
    #sendEmail-Button, .ContactMe-form-top > input{
        font-size: 24px;
        padding: 14px;
    }
    .ContactMe-form > textarea{
        font-size: 24px;
        padding: 16px;
    }
    .ContactMe i {
        font-size: 50px;
        width: 60px;
    }
}

/* Larger Screens */
@media  screen and (min-width: 1200px) and (max-height: 1080px){
    .ContactMe p, .contact-option > a {
        font-size: 15px;
    }
    .ContactMe i {
        font-size: 25px;
        width: 35px;
    }
}

/* Small Screens */
@media  screen and (max-width: 1200px){
    .ContactMe {
        align-items: center;
    }
    .ContactMe p, .contact-option > a {
        font-size: 14px;
    }
    .ContactMe-form-wrapper{
        flex-flow: column-reverse;
        height: auto;
    }
    .ContactMe-form-top > input{
        width: 180px;
    }
    .ContactMe-form > textarea{
        width: 404px;
    }
    .contact-options{
        flex-direction: row;
        row-gap: 0px;
        column-gap: 20px;
    }
    #sendEmail-Button{
        width: 442px;
    }
}

/* Mini Screens */
@media  screen and (max-width: 768px){
    .ContactMe i {
        font-size: 22px;
        width: 28px;
    }
    .ContactMe p, .contact-option > a {
        font-size: 12px;
    }
    .ContactMe-form-top > input{
        width: 140px;
    }
    .ContactMe-form > textarea{
        width: 324px;
    }
    #sendEmail-Button{
        width: 360px;
    }
}

/* Mobile */
@media  screen and (max-width: 480px){
    .contact-options{
        display: flex;
        flex-direction: column;
    }
    .contact-option{
        height: fit-content;
        padding: 5px;
    }
    .ContactMe i {
        font-size: 12px;
        width: 18px;
    }
    .ContactMe p, .contact-option > a {
        font-size: 12px;
    }
    .ContactMe-form-top > input{
        width: 85px;
    }
    .ContactMe-form > textarea{
        width: 214px;
    }
    #sendEmail-Button{
        width: 248px;
    }
}
