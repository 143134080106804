.RecentWork {
    display: flex;
    align-items: center;
    column-gap: 20px;
    /* width: 100%; */
    flex-wrap: wrap;
}

.RecentWork > a {
    text-decoration: none;
    color: white;
    text-align: center;
}

.RecentWork > a > img {
    width: 500px;
    height: 250px;
    object-fit: scale-down;
}

/*Project Text Colors*/

#project-text-supereats{
    color: #2dbe65;
}
#project-text-mangomusic{
    color: #ffb13b;
}
#project-text-kbnb{
    color: #47bbff;
}

/* Media Queries */

/* 4k */
@media  screen and (min-width: 3000px) and (min-height: 1795px){
    .RecentWork > a > img {
        width: 650px;
        height: 325px;
    }
    .RecentWork h3 {
        font-size: 24px;
    }
    .RecentWork p {
        font-size: 16px;
    }
}

/* UW */
@media screen and (min-width: 3000px) and (min-height: 1080px){
    .RecentWork > a > img {
        width: 625px;
    }
}


/* Larger Screens */
@media  screen and (min-width: 1200px) and (max-height: 1080px){
    .RecentWork > a > img {
        width: 400px;
        height: 200px;
    }
    .RecentWork h3 {
        font-size: 14px;
    }
    .RecentWork p {
        font-size: 12px;
    }
}

/* Small Screens */
@media  screen and (max-width: 1200px){
    .RecentWork {
        justify-content: center;
    }
    .RecentWork > a > img {
        width: 425px;
        height: 212.5px;
    }
    .RecentWork h3 {
        font-size: 12px;
    }
    .RecentWork p {
        font-size: 10px;
    }
  }

/* Mini Screens */
@media  screen and (max-width: 768px){
    .RecentWork > a > img {
        width: 300px;
        height: 150px;
    }
}

/* Mobile */
/* @media  screen and (max-width: 480px){
    .RecentWork > a > img {
        width: 200px;
        height: 100px;
    }
    .RecentWork h3 {
        font-size: 10px;
    }
    .RecentWork p {
        font-size: 6px;
    }
} */
